import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import { Bar } from 'react-chartjs-2';
//import DoughnutChart from './DoughnutChart';
//import ChartDataLabels from 'chartjs-plugin-datalabels';

function Inicio() {

  /*
  const options = {
    title: {
        display:true,
        text: "bla",
        fontSize:16
    },
    plugins: {
        // Change options for ALL labels of THIS CHART
        datalabels: {
            anchor: 'center',
            formatter: function(value, context) {
                return value + '%';
            },
            font: {
                size:16
                
            }
        }
    }
  
 }

  const encuesta = {

    labels: [
      "Áreas comunes (pasillos, hall)",
      "CAPS - Centros de atención primaria",
      "Diagnóstico por imágenes",
      "Consultorios Externos"
      
  ],
  datasets: [
      {   label: " ¿Qué área/ sector o Servicio deberíamos mejorar en cuanto a su infraestructura?",
          data: [
            60,
            20,
            10,
            10
          ],
          backgroundColor: [
            '#85bce2',
            '#93c9e2',
            '#a7d4e2',
            '#bddfe3'
          ]
      }
  ]
   
  }
*/
    
    return (
        <Container>
          <Row>
            <Col>
                <br /> 
                <h4>Transición</h4>
                <h6><a style={{color: "blue"}} href="http://gestionabierta.centrodesalud.com.ar/pdf/Informe-transición-2023.pdf" download>DESCARGAR INFORME DE TRANSICIÓN</a> </h6>
                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/2StHUSmv5Ng?rel=0&modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <br/>

                <h5>Gestión abierta: Nuestro Compromiso con la continuidad  de nuestros logros</h5>
                <p>Llevar adelante cambios de gestión enfocados en el bienestar de la ciudadanía, requiere de planificación gestión y trabajo y  queremos garantizar la continuidad de cada una de las gestiones a partir de transiciones responsables. Esto es un compromiso y apuesta real de vocación pública y servicio.</p>
                <p>Este informe invita, entonces, a reafirmar el compromiso de un traspaso de gestión con la predisposición y colaboración que la función pública amerita; brindando acceso a todo lo que el nuevo equipo de gobierno necesite para comenzar sus tareas y quien inicia en la función pública ofreciendo una actitud de apertura y respeto hacia el trabajo realizado, información y aprendizajes compartidos.</p>
                <p>Resulta importante aclarar que en este apartado vamos a establecer datos respecto de los recursos económicos y financieros solamente. Sabemos que los recursos económicos son limitados, pero no nos ponemos techo a la hora de salvar la vida de nuestros vecinos. Primero resolvemos cada situación de salud en la que nos toca intervenir. Luego analizamos la situación económica financiera. </p>
                <p>Es por eso que nos parece de igual importancia que VOS, vecino/ vecina que siempre fuiste el destinatario de nuestro trabajo, de nuestros esfuerzos y para quien día a día avanzamos y sin dudas tenemos un hospital del que estamos orgullosos. Tengas a disposición esta información, para que puedas evaluar y también tomar una decisión informada sobre la salud que Tres Arroyos y vos merecen, que puedas ser partícipe de todo aquello a lo que debe darse continuidad.</p>
                <h6><a style={{color: "blue"}} href="http://gestionabierta.centrodesalud.com.ar/pdf/Informe-transición-2023.pdf" download>ACÁ, en  Gestión Abierta podes descargar el INFORME DE TRANSICIÓN</a> </h6> 

                <p>Esto es un resumido informe de transición de las principales acciones llevadas adelante durante el año 2023.</p>
                <p>Estamos felices del camino recorrido y de  los proyectos que nos permiten seguir, porque lo hacemos con nuestro equipo, con personal propio y somos un hospital público  de gestión Municipal compuesto por más de 600 personas que  avanzamos para nuestra comunidad porque #Unidos construimos salud</p>

                <hr />               

                <br /> 
                <h4>Gestión Abierta</h4>

                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ota5Xsc1o0U?rel=0&modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>

                <hr/>
                <p>La gestión permanente es nuestro lineamiento de trabajo y estimular tu participación es importante para nosotros. Tu opinión nos hace crecer.</p>
                <p>Para seguir evolucionando con una gestión más eficaz, eficiente y empática necesitamos que participes y para ello primero queremos rendirte cuentas de la administración de nuestros recursos, a vos, que sos quien recibe nuestros servicios.</p>
                <p>En Gestión Abierta ahora encontrás las estadísticas asistenciales, la evolución de nuestras consultas en gráficos y nuestras estadísticas presupuestarias (nuestro presupuesto, como lo gastamos, quien lo gasta y las inversiones y obras que hemos podido concretar).</p>
                <p>Queremos tu opinión para mejorar nuestros servicios en base a tus necesidades y mejorar el nivel de discusión pública. Déjanos tus dudas o sugerencias al mail contacto@centrodesaludtsas.com.ar</p>
                <p>Completa nuestra  <a href="https://forms.gle/T4jGhJ5NRaj2nbMA7" target="_blank" rel="noopener noreferrer">ENCUESTA</a> y ayúdanos a seguir evolucionando porque #Unidosconstruimossalud.</p>           
            

               
            </Col>
          </Row>
        </Container>
    )
}

export default Inicio