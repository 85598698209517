import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Table from 'react-bootstrap/Table';
//import BarChartRecursos from './BarChartRecursos.jsx';
import {
    datosPresupuestarios
} from '../data/data.js';

//import BarChartSelectSalas from './BarChartSelectSalas.jsx';
import DoughnutChart from './DoughnutChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function PresupuestoFinanciamiento() {

  return (
    <div>
      <Container id="contain">
      <br/>
      <h3>¿Cómo se financia?</h3>
      <hr/>
        <Row>
          <Col>
            <br />
            <p>Mediante esta clasificación es posible saber de donde provienen los recursos que dispone el Centro de Salud para financiar sus gastos.</p>
            <p>Se la llama clasificación por fuente de financiamiento.</p>
            <p>Esta clasificación facilita el mantenimiento del equilibrio entre gastos y recursos en el sentido amplio, en tanto que no es posible efectuar erogaciones en la medida de que no se registre el financiamiento.</p>
          </Col>
          <Col>
            <DoughnutChart plugins={[ChartDataLabels]} data={datosPresupuestarios().distribucion_financiamiento} height={250} stacked={false} title="Distribución por fuente de financiamiento (Presupuesto 2022)"/>             
          </Col>
        </Row>
        <hr />
        <br />
        <h4>Recursos - Comparativa (Presupuesto) </h4>
        <br />
        <Row>        
          <Col xsHidden>
          <Table striped responsive>
              <thead>
                <tr>
                  <th colspan="4">RECURSOS DE JURISDICCIÓN MUNICIPAL</th>
                </tr>
                <tr>
                  <th></th>
                  <th>2022</th>
                  <th>2023</th>
                  <th>Diferencia</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>Tasa por Servicios Asistenciales</td>
                  <td>$51.134.396 </td>
                  <td>$98.172.701</td>
                  <td>92%</td>
                </tr>
                <tr>
                  <td>Ingresos por INSSJP</td>
                  <td>$181.568.362</td>
                  <td>$236.879.029 </td>
                  <td>30%</td>
                </tr>
                <tr>
                  <td>Donaciones</td>
                  <td>$500.000</td>
                  <td>$800.000</td>
                  <td>60%</td>                 
                </tr>
                <tr>
                  <td>Resto Ingresos Municipales</td>
                  <td>$1.200.000</td>
                  <td>$1.440.000</td>
                  <td>20%</td>                 
                </tr>
                <tr>
                  <td>Anticipos Jubilatorios IPS</td>
                  <td>$3.000.000</td>
                  <td>$4.500.000</td>
                  <td>50%</td>                 
                </tr>
                <tr>
                  <td><b>Sub-Total</b></td>
                  <td>$237.402.758</td>
                  <td>$341.791.730</td>
                  <td>44%</td>                 
                </tr>
                <tr>
                  <th colspan="4">REMESAS DE ADMINISTRACIÓN CENTRAL</th>
                </tr>
                <tr>
                  <td>Tasa Solidaria de Salud</td>
                  <td>$159.257.553</td>
                  <td>$301.984.896</td>
                  <td>90%</td>                 
                </tr>
                <tr>
                  <td>Para financiaciones corrientes</td>
                  <td>$846.369.379</td>
                  <td>$2.008.770.244</td>
                  <td>137%</td>                 
                </tr>
                <tr>
                  <td><b>Sub-Total</b></td>
                  <td>$1.005.626.932</td>
                  <td>$2.310.755.140</td>
                  <td>130%</td>                 
                </tr>
                <tr>
                  <td><b>TOTAL</b></td>
                  <td>$1.243.029.690</td>
                  <td>$2.652.546.870</td>
                  <td>113%</td>                 
                </tr>
              </tbody>
            </Table>        
          </Col>
        </Row>
        <hr />

      {/*
        <Row>
          <Col xs={12} md={12}>
          <BarChartRecursos data={datosPresupuestarios()} defaultData={datosPresupuestarios().recursos_comparativa} height={150} stacked={true} title="Recursos"/>
          </Col>
        
        </Row>
       
      */}

      </Container>
    </div>
  );
}

export default PresupuestoFinanciamiento;