import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {Form} from 'react-bootstrap';

function BarChart(props) {

    const [actualData, setData] = useState(props.defaultData);

    const options = {
        scales: {
             xAxes: [{
                 stacked: props.stacked,
                 ticks: {
                    beginAtZero:true,
                    fontSize:14
                }
             }],
             yAxes: [{
                 stacked: props.stacked,
                 ticks: {
                    beginAtZero:true,
                    fontSize:14
                 },
             }]
        },
        title: {
            display:true,
            text: props.title,
            fontSize:16
        }
     }

    function handleChange(e) {
        e.preventDefault();
        setData(props.data[e.target.value]);
    }

    
    function OptionsList(props) {
        const selectOptions = Object.getOwnPropertyNames(props.options);
        const optionsList = selectOptions.map((element) =>
            <option key={JSON.stringify(element)} value={element}>{element}</option>
        );
        return (
            <Form.Control id="bla" as="select" onChange={handleChange}>
                {optionsList}
            </Form.Control>
        )
    }



    return (
        <div>    
            {props.dataSelect &&    

            <>
                <OptionsList options={props.data}/>                   
            </>

            }
        
            < Bar data={actualData} options={options} height={props.height} />
        
        </div>
    )
}

export default BarChart



 //TODO: porque como componente no funciona? tuve que agregar el condicional de JSX para renderizar el fragmento. Investigar
    /*
    function DataSelect(dataSelect){
        if (dataSelect) {
            return (
                      
                <>
                <Form.Control as="select" onChange={handleChange}>
                    <option value="capsBMachadoTotalData" >Caps Benito Machado</option>
                    <option value="caps25deMayoTotalData">Caps 25 de Mayo</option>
                </Form.Control>
                </>
                
            )
        }else{
            return null;
        } 
    }
    */