import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';

function Evolucion() {


    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Container>
          <Row>
            <Col>
                <br /> 
                <h5>Evolución</h5>
                <hr/>

                <p>Nuestro hospital es el eje de la salud pública de nuestro distrito, que posee 75 mil habitantes y en base a las obras que hemos podido concretar. Hemos ampliado el hospital a 8.010 m<sup>2</sup> y se han refaccionado y restaurado a nuevo sectores existentes por 1719 m2, que habían quedado obsoletos dentro del plan sanitario de la Provincia. Te lo mostramos en imágenes.</p>

                <Carousel activeIndex={index} onSelect={handleSelect} fade="true" interval="1000000">

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion/1.jpg"
                        alt="First slide"
                        />
                        <Carousel.Caption>
                        <p>
                            <b>2004</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion/2.jpg"
                        alt="Second slide"
                        />
                         <Carousel.Caption>
                        <p>
                            <b>2009</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion/6.jpg"
                        alt="Third slide"
                        />
                         <Carousel.Caption>
                        <p>
                            <b>2017</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion/8.jpg"
                        alt="Third slide"
                        />
                          <Carousel.Caption>
                        <p>
                            <b>2022</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                
                </Carousel>

                <br />

                <Carousel activeIndex={index} onSelect={handleSelect} fade="true" interval="1000000">

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_d/1.jpg"
                        alt="First slide"
                        />
                        <Carousel.Caption>
                        <p>
                            <b>2004</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_d/2.jpg"
                        alt="Second slide"
                        />
                         <Carousel.Caption>
                        <p>
                            <b>2009</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_d/6.jpg"
                        alt="Third slide"
                        />
                         <Carousel.Caption>
                        <p>
                            <b>2017</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_d/8.jpg"
                        alt="Third slide"
                        />
                          <Carousel.Caption>
                        <p>
                            <b>2022</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                
                </Carousel>

                <br />

                <Carousel activeIndex={index} onSelect={handleSelect} fade="true" interval="1000000">

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_s/1.jpg"
                        alt="First slide"
                        />
                        <Carousel.Caption>
                        <p>
                            <b>2004</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_s/2.jpg"
                        alt="Second slide"
                        />
                         <Carousel.Caption>
                        <p>
                            <b>2009</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_s/6.jpg"
                        alt="Third slide"
                        />
                         <Carousel.Caption>
                        <p>
                            <b>2017</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_evolucion_s/8.jpg"
                        alt="Third slide"
                        />
                          <Carousel.Caption>
                        <p>
                            <b>2022</b>
                        </p>
                        </Carousel.Caption>
                    </Carousel.Item>
                
                </Carousel>
                

                
            
                <br></br>

            </Col>
          </Row>
        </Container>
    )
}

export default Evolucion