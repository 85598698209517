import React from 'react'
import { Doughnut } from 'react-chartjs-2'


function DoughnutChart(props) {   

    const options = {
        title: {
            display:true,
            text: props.title,
            fontSize:16
        },
        plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
                anchor: 'center',
                formatter: function(value, context) {
                    return value + '%';
                },
                font: {
                    size:16
                    
                }
            }
        }
      
     }

    return (
        <Doughnut data={props.data} plugins={props.plugins} width={props.width} height={props.height} options={options} />
    )
}

export default DoughnutChart
