import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
    consultasTotalesCaps
} from '../data/data.js';

import BarChartSelectSalas from './BarChartSelectSalas.jsx';
import DoughnutChart from './DoughnutChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function Asistencia_Unidades_Perifericas() {

  return (
    <div>
      <Container id="contain">
      <br/>
      <h5>Estadísticas de los Centros de Atención Primaria de la ciudad</h5>
      <hr/>
        <Row>
          <Col>
            <BarChartSelectSalas data={null} dataSelect={true} height={250} stacked={false} title="Consultas Centros de Atención Primaria de la ciudad"/>
          </Col>
          <Col>
          <DoughnutChart plugins={[ChartDataLabels]} data={consultasTotalesCaps().todos} height={10} width={10} title="Distribución de consultas en Centros de Atención Primaria de la ciudad"/>
          </Col>
        </Row>
        
        {/*
        <Row>
          <Col>
            <BarChart data={consultasTotalesCaps()} defaultData={consultasTotalesCaps().tasa_crecimiento} height={250} stacked={false} title="Consultas Centros de Atención Primaria periféricos"/>
          </Col>
          <Col>
          </Col>
        </Row>

        */}

      <hr/>


      <p>Fuente de los datos: Área de Estadísticas del Centro Municipal de Salud</p>
      
      </Container>
    </div>
  );
}

export default Asistencia_Unidades_Perifericas;