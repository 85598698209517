/* Estadísticas Hospital*/
/*


export const consultoriosExternosTotalData = {
    labels: [
      2009,
      2010,
      2011,
      2012,
      2013,
      2014,
      2015,
      2016,
      2017,
      2018,
      2019
  ],
  datasets: [
      {   label: "Totales",
          data: [
            96498,
            101208,
            104983,
            112183,
            112454,
            120532,
            123678,
            127945,
            134202,
            146846,
            149644
          ],
          backgroundColor:'#85bbe2'
      }
  ]
}

export const consultoriosExternosTasaDeCrecimientoData = {
  labels: [
      2004,
      2005,
      2006,
      2007,
      2008,
      2009,
      2010,
      2011,
      2012,
      2013,
      2014,
      2015,
      2016,
      2017,
      2018,
      2019
  ],
  datasets: [
      {   label: "Tasa de crecimiento (%)",
          data: [
              0,
              16,
              3,
              3,
              1,
              2,
              5,
              4,
              7,
              0,
              7,
              3,
              3,
              5,
              9,
              2
          ],
          backgroundColor: '#abc456'
      }
  ]

}

export const capsTotalData = {
  labels: [
    2004,
    2005,
    2006,
    2007,
    2008,
    2009,
    2010,
    2011,
    2012,
    2013,
    2014,
    2015,
    2016,
    2017,
    2018,
    2019
  ],
  datasets: [
      {   label: "Consultas totales",
          data: [
            23417,
            25091,
            27495,
            35402,
            37940,
            39910,
            42216,
            44730,
            48158,
            48158,	
            60625,
            63136,
            63219,
            64883,
            67964,
            69919
          ],
          backgroundColor:'#85bbe2'
      }
  ]
}

export const capsDistribucionTotalData = {
  labels: [
    '25 de Mayo',
    'Benito Machado',
    'Boca',
    'Colegiales',
    'Fonavi Terminal',
    'Ranchos Virgen de Luján',
    'Ruta 3 Sur',
    'Santa Teresita',
    'Municipal',
    'Villa Italia',
    'Olimpo'
    ],
  datasets: [
    { label: "Distribucion por CAPS",
      data: [
        71032,
        50268,
        68625,
        66959,
        68674,
        120123,
        85013,
        62990,
        51056,
        77417,
        40106
      ],
      backgroundColor:'#85bbe2'
   }

  ]
}

export const capsTasaDeCrecimientoData = {
  labels: [
      2004,
      2005,
      2006,
      2007,
      2008,
      2009,
      2010,
      2011,
      2012,
      2013,
      2014,
      2015,
      2016,
      2017,
      2018,
      2019
  ],
  datasets: [
      {   label: "Tasa de crecimiento (%)",
          data: [
            7,
            10,
            29,
            7,
            5,
            6,
            6,
            8,
            0,
            26,
            4,
            0,
            3,
            5,
            3
          ],
          backgroundColor: '#abc456'
      }
  ]

}

*/

function consultasHospital(){
  return {
    consultoriosExternosData : {
      labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
      ],
      datasets: [
          {   label: "Consultorios médicos",
              data: [
                  79636,
                  83768,
                  86753,
                  89621,
                  90256,
                  96327,
                  99089,
                  103316,
                  108652,
                  118790,
                  119968,
                  125083,
                  133094,
                  138184
              ],
              backgroundColor: '#abc456'
          },
          {
              label: "Consultorios paramédicos",
              data: [
                  16862,
                  17440,
                  18230,
                  22562,
                  22198,
                  24205,
                  24589,
                  24629,
                  25550,
                  28056,
                  29692,
                  28507,
                  28203,
                  27356
              ],
              backgroundColor:'#85bbe2'
          }
      ]
  
    },
    consultoriosExternosTotalData : {
    labels: [
      2009,
      2010,
      2011,
      2012,
      2013,
      2014,
      2015,
      2016,
      2017,
      2018,
      2019,
      2020,
      2021,
      2022
  ],
  datasets: [
      {   label: "Totales",
          data: [
            96498,
            101208,
            104983,
            112183,
            112454,
            120532,
            123678,
            127945,
            134202,
            146846,
            149660,
            153590,
            161297,
            165540
          ],
          backgroundColor:'#85bbe2'
      }
  ]
    },


    atencionesGuardiaData : {
      labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
      ],
      datasets: [
          {   label: "Guardia de adultos",
              data: [
                21212,
                27193,
                29301,
                31241,
                30065,
                34252,
                31607,
                32593,
                38037,
                43266,
                51169,
                53478,
                52977,
                57999
              ],
              backgroundColor: '#abc456'
          },
          {
              label: "Guardia pediátrica",
              data: [
                8337,
                10092,
                9008,
                9196,
                8866,
                8839,
                11542,
                13001,
                14065,
                17307,
                18931,
                19049,
                16458,
                23515

              ],
              backgroundColor:'#85bbe2'
          }
      ]
  
    },


    atencionesGuardiaTotalData : {
      labels: [
        2009,
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019,
        2020,
        2021,
        2022
    ],
    datasets: [
        {   label: "Totales",
            data: [
              29549,
              37285,
              38309,
              40437,
              38931,
              43091,
              43149,
              45594,
              52102,
              60573,
              70100,
              72527,
              69435,
              81514


             
            ],
            backgroundColor:'#85bbe2'
        }
    ]
      },

      


    tasaDeCrecimiento : {
    labels: [
        2009,
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019
    ],
    datasets: [
        {   label: "Tasa de crecimiento (%)",
            data: [
                2,
                5,
                4,
                7,
                0,
                7,
                3,
                3,
                5,
                9,
                2
            ],
            backgroundColor: '#abc456'
        }
    ]
  
    },
    internacionesAnualesHospital : {
      labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
      ],
      datasets: [
          {   label: "Total de internaciones",
              data: [
                3302,
                3943,
                4196,
                4240,
                4463,
                5047,
                5082,
                5567,
                5603,
                6465,
                6525,
                6551,
                6653,
                7134
              ],
              backgroundColor: '#85bbe2'
          }
      ]
    
    },
    cirugiasAnualesHospital : {
      labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
      ],
      datasets: [
          {   label: "Total de cirugías",
              data: [
                945,
                1069,
                1119,
                1084,
                1091,
                1136,
                1363,
                1216,
                1234,
                1400,
                1408,
                991,
                1226,
                1551


              ],
              backgroundColor: '#85bbe2'
          }
      ]
    
    },
    nacimientosAnualesHospital : {
      labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
      ],
      datasets: [
          {   label: "Total de nacimientos",
              data: [
                373,
                374,
                402,
                377,
                469,
                465,
                443,
                397,
                377,
                423,
                368,
                299,
                283,
                282
              ],
              backgroundColor: '#85bbe2'
          }
      ]
    
    },

    diagnosticoPorImagenesAnuales: {
      labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
      ],
      datasets: [
          {   label: "Total de radiologías simples",
              data: [
                25613,
                25122,
                23728,
                21777,
                19112,
                14596,
                15909,
                15771,
                16246,
                16379,
                21059,
                15706,
                14777,
                9196

              ],
              backgroundColor: '#abc456'
          },
          {
            label: "Total de ecografías",
            data: [
              1899,
              3173,
              4064,
              4344,
              4740,
              5358,
              4809,
              4839,
              5066,
              5167,
              6204,
              4764,
              5151,
              6002



            ],
            backgroundColor:'#85bbe2'
        },
        {
          label: "Total de tomografías computadas",
          data: [
            162,
            1160,
            3263,
            3944,
            2454,
            4438,
            1926,
            4061,
            4003,
            4189,
            5713,
            1517,
            4584,
            5384




          ],
          backgroundColor:'#c4d38a'
      }


      ]
      
    },
    
  }
}



/*
function consultasTotalesPorCaps(){

  return { 
    capsBMachadoTotalData : {
      labels: [
        2009,
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019
      ],
      datasets: [
          {   label: "Consultas totales",
              data: [
                2348,
                2765,
                3202,
                3723,
                3723,
                4221,
                3750,
                4597,
                5177,
                5901,
                7272
              ],
              backgroundColor:'#85bbe2'
          }
      ]
    },
  
        caps25deMayoTotalData : {
          labels: [
            2009,
            2010,
            2011,
            2012,
            2013,
            2014,
            2015,
            2016,
            2017,
            2018,
            2019
          ],
          datasets: [
              {   label: "Consultas totales",
                  data: [
                    3465,
                    3505,
                    3613,
                    4141,
                    4141,
                    4954,
                    6318,
                    7096,
                    6142,
                    6329,
                    6061
        
                  ],
                  backgroundColor:'#85bbe2'
              }
          ]
        }
  }


}
*/

function consultasTotalesCaps(){
  return {
    todos: { labels: [
      '25 de Mayo',
      'Benito Machado',
      'Boca',
      'Colegiales',
      'Fonavi Terminal',
      'Ranchos Virgen de Luján',
      'Ruta 3 Sur',
      'Santa Teresita',
      'Municipal',
      'Villa Italia',
      'Olimpo'
      ],
    datasets: [
      { label: "Distribucion por CAPS",
        data: [
          9,
          8,
          10,
          8,
          8,
          15,
          10,
          8,
          6,
          10,
          7
        ],
        backgroundColor: [
          '#85bce2',
          '#93c9e2',
          '#a7d4e2',
          '#bddfe3',
          '#d5e8e8',
          '#c4d38a',
          '#ecf2f1',
          '#ccede1',
          '#b4e6c7',
          '#a6dda6',
          '#a4d280'
        ]
     }
  
    ]
  },
    tasa_crecimiento: {
      labels:[
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019
    ],
    datasets: [
        {   label: "Tasa de crecimiento",
            data: [
              6,
              6,
              8,
              0,
              26, 
              4,
              0,
              3,
              5,
              3
              


            ],
            backgroundColor:'#85bbe2'
        }
    ]
    },
    todos_localidades: { 
        labels: [
          2009,
          2010,
          2011,
          2012,
          2013,
          2014,
          2015,
          2016,
          2017,
          2018,
          2019,
          2020,
          2021,
          2022
        ],
        datasets: [
          { label: "Consultas totales",
            data: [
              16312,
              17940,
              19332,
              20403,
              20465,
              22420,
              22683,
              24125,
              24173,
              34748,
              37590,
              35213,
              40564,
              39395
            ],
            backgroundColor: '#85bce2'
          }
  
        ]
    },
    tasa_crecimiento_localidades: {
      labels:[
        2010,
        2011,
        2012,
        2013,
        2014,
        2015,
        2016,
        2017,
        2018,
        2019
    ],
    datasets: [
        {   label: "Tasa de crecimiento",
            data: [
              10,
              8,
              6,
              0,
              10,
              1,
              0,
              6,
              44,
              8
            ],
            backgroundColor:'#85bbe2'
        }
    ]
    }
  }
}


function datosPresupuestarios(){
  return {

    distribucion_financiamiento: { labels: [
      'Tasa de salud',
      'Recursos propios',
      'Financiaciones corrientes'
      ],
    datasets: [
      { label: "Fuentes de financiamiento",
        data: [
          12.8,
          19.1,
          68.1          

          
        ],
        backgroundColor: [
          '#85bce2',
          '#93c9e2',
          '#a7d4e2'
        ]
     }  
    ]
  },


  origenes_gastos: {labels: [
    'Atención Médica Hospitalaria',
    'Atención Primaria en Centros de Atención Primaria',
    'Conducción y administración'
    ],
  datasets: [
    { label: "Origenes de gastos",
      data: [
        76,
        15,
        9
      ],
      backgroundColor: [
        '#85bce2',
        '#93c9e2',
        '#a7d4e2'
      ]
   }  
  ]



  },

  gastos_distribucion: {labels: [
    'Gastos en personal',
    'Bienes de consumo',
    'Servicios no personales'
    ],
  datasets: [
    { label: "Origenes de gastos",
      data: [
        81,
        11,
        8
        
      ],
      backgroundColor: [
        '#85bce2',
        '#93c9e2',
        '#a7d4e2'
      ]
   }  
  ]



  },





  recursos_comparativa: { labels: [
      '2019',
      '2020'
      ],
    datasets: [
      { label: "Tasa por Servicios Asistenciales",
        data: [
          20032000,
          33896500
        ],
        backgroundColor: [
          '#2F7EF9',
          '#2F7EF9'
        ]
      },
      { label: "Ingresos por INSSJP",
        data: [
          47688000,
          68700000
        ],
        backgroundColor: [
          '#7c9fd6',
          '#7c9fd6'
        ]
      },
      { label: "Donaciones",
        data: [
          200000,
          240000
        ],
        backgroundColor: [
          '#94db67',
          '#94db67'
        ]
      },
      { label: "Ingresos por INSSJP",
        data: [
          47688000,
          68700000
        ],
        backgroundColor: [
          '#ba4773',
          '#ba4773'
        ]
      },
      { label: "Ingresos por INSSJP",
        data: [
          47688000,
          68700000
        ],
        backgroundColor: [
          '#2FF999',
          '#2FF999'
        ]
      },
      { label: "Tasa Solidaria de Salud",
        data: [
          39787066,
          63017845
        ],
        backgroundColor: [
          '#385923',
          '#385923'
        ]
      },
      { label: "Para financiamientos corrientes",
        data: [
          419489066,
          615833744
        ],
        backgroundColor: [
          '#3c4554',
          '#3c4554'
        ]
      }

    ]
  }
}

}





export {consultasTotalesCaps, consultasHospital, datosPresupuestarios}