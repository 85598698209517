import React from 'react';

import 'bootstrap/dist/css/bootstrap.min.css';
import './App.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav'
import NavDropdown from 'react-bootstrap/NavDropdown'
//import Form from 'react-bootstrap/Form'
//import FormControl from 'react-bootstrap/FormControl'
//import Button from 'react-bootstrap/Button'
import {Helmet} from "react-helmet";
//import BarChart from './components/BarChart';

//import DoughnutChart from './components/DoughnutChart';
//import PolarAreaChart from './components/PolarAreaChart.jsx';
//import {datosPresupuestarios,
  //      consultasTotalesCaps,
  //      consultasHospital
//} from './data/data.js';

import {Chart} from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Inicio from './components/Inicio.jsx';
import AsistenciaHospital from './components/AsistenciaHospital.jsx';
import AsistenciaUnidadesPerifericas from './components/Asistencia_Unidades_Perifericas.jsx';
import AsistenciaUnidadesLocalidades from './components/Asistencia_Unidades_Localidades.jsx';
import PresupuestoFinanciamiento from './components/PresupuestoFinanciamiento.jsx';
import PresupuestoGastosOrigen from './components/PresupuestoGastosOrigen.jsx';
import PresupuestoGastosDistribucion from './components/PresupuestoGastosDistribucion.jsx';
import Inversiones from './components/Inversiones.jsx';
import Evolucion from './components/Evolucion.jsx';
import Futuro from './components/Futuro.jsx';


function App() {

  Chart.plugins.unregister(ChartDataLabels);

  return (


    <Router>
      <div className="App">
        <Helmet>
          <meta charSet="utf-8" />
          <title>Gestión Abierta - Centro Municipal de Salud</title>
          <meta name="viewport" content="width=device-width, initial-scale=1"></meta>
          <link rel="preconnect" href="https://fonts.gstatic.com" />
          <link href="https://fonts.googleapis.com/css2?family=Poppins&display=swap" rel="stylesheet"></link>
        </Helmet>
        <Container fluid>
          <Row>
            <Col>
              <>
              <Navbar expand="lg">
                <Navbar.Brand href="#home">
                  Centro de Salud
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <Nav.Link><Link className="text-link" to="/">Inicio</Link></Nav.Link>
                    
                    <NavDropdown title="Asistencia" id="basic-nav-dropdown">
                      <NavDropdown.Item><Link className="text-link" to="/asistencia/hospital">Hospital</Link></NavDropdown.Item>
                      <NavDropdown.Item><Link className="text-link" to="/asistencia/unidades-perifericas">Centros de Atención Primaria de la ciudad</Link></NavDropdown.Item>
                      <NavDropdown.Item><Link className="text-link" to="/asistencia/unidades-localidades">Centros de Atención Primaria de las localidades</Link></NavDropdown.Item>
                      <NavDropdown.Divider />
                    </NavDropdown>
                    <NavDropdown title="Presupuesto" id="basic-nav-dropdown">
                      <NavDropdown.Item><Link className="text-link" to="/presupuesto/fuentes-financiamiento">¿Cómo se financia?</Link></NavDropdown.Item>
                      <NavDropdown.Item><Link className="text-link" to="/presupuesto/gastos-origen">¿Quién gasta?</Link></NavDropdown.Item>
                      <NavDropdown.Item><Link className="text-link" to="/presupuesto/gastos-distribucion">¿En qué y para qué se gasta?</Link></NavDropdown.Item>
                      <NavDropdown.Divider />
                    </NavDropdown>
                    <Nav.Link><Link className="text-link" to="/inversiones">Inversiones</Link></Nav.Link>
                    <Nav.Link><Link className="text-link" to="/evolucion">Evolución</Link></Nav.Link>
                    <Nav.Link><Link className="text-link" to="/futuro">Futuro</Link></Nav.Link>

                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              </>
            </Col>
          </Row>
        </Container>
      </div>

      <Switch>

          <Route path="/futuro">
            <Futuro />
          </Route>

          <Route path="/evolucion">
            <Evolucion />
          </Route>

          <Route path="/inversiones">
            <Inversiones />
          </Route>

          <Route path="/asistencia/hospital">
            <AsistenciaHospital />
          </Route>
          <Route path="/asistencia/unidades-perifericas">
            <AsistenciaUnidadesPerifericas />
          </Route>
          <Route path="/asistencia/unidades-localidades">
            <AsistenciaUnidadesLocalidades />
          </Route>

          <Route path="/presupuesto/fuentes-financiamiento">
            <PresupuestoFinanciamiento />
          </Route>
          <Route path="/presupuesto/gastos-origen">
            <PresupuestoGastosOrigen />
          </Route>
          <Route path="/presupuesto/gastos-distribucion">
            <PresupuestoGastosDistribucion />
          </Route>

          <Route path="/">
            <Inicio />
          </Route>

         


        </Switch>

    </Router>
  );
}

export default App;