import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';


//import BarChart from './BarChart.jsx';
//import {
//    consultasTotalesCaps
//} from '../data/data.js';

//import BarChartSelectSalas from './BarChartSelectSalas.jsx';
import DoughnutChart from './DoughnutChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

import {
  datosPresupuestarios
} from '../data/data.js';

function PresupuestoGastosDistribucion() {

  return (
    <div>
      <Container id="contain">
      <br/>
      <h3>¿En qué y para qué se gasta?</h3>
      <hr/>
        <Row>
          <Col>
            <br />
            <p>Estas clasificaciones se conocen como clasificación por objeto del gasto.</p>
            <p>Se puede ver qué tipo de bienes y servicios compra o contrata el Centro de Salud.</p>
            <p>Permite identificar con claridad y transparencia los bienes y servicios que se adquieren, las transferencias que se realizan y las aplicaciones previstas en el presupuesto.</p>
          </Col>
          <Col>
            <DoughnutChart plugins={[ChartDataLabels]} data={datosPresupuestarios().gastos_distribucion} height={250} stacked={false} title="Distribución por objeto de gasto de presupuesto 2022"/>             
          </Col>
        </Row>
        <hr />
      </Container>
    </div>
  );
}

export default PresupuestoGastosDistribucion;

