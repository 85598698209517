import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import {Form} from 'react-bootstrap';

function BarChartSelectSalas(props) {


    const datosSalas = {
        "capsBMachadoTotalData" : {
            labels: [
              2009,
              2010,
              2011,
              2012,
              2013,
              2014,
              2015,
              2016,
              2017,
              2018,
              2019,
              2020,
              2021,
              2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        2348,
                        2765,
                        3202,
                        3723,
                        3723,
                        4221,
                        3750,
                        4597,
                        5177,
                        5901,
                        7272,
                        6192,
                        6566,
                        7054


                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
          },
    
        "caps25deMayoTotalData" : {
        labels: [
            2009,
            2010,
            2011,
            2012,
            2013,
            2014,
            2015,
            2016,
            2017,
            2018,
            2019,
            2020,
            2021,
            2022
        ],
        datasets: [
            {   label: "Consultas totales",
                data: [
                    3465,
                    3505,
                    3613,
                    4141,
                    4141,
                    4954,
                    6318,
                    7096,
                    6142,
                    6329,
                    6061,
                    6574,
                    6127,
                    6541


                ],
                backgroundColor:'#85bbe2'
            }
        ]
        },

        "cicBocaTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        4465,
                        4601,
                        4902,
                        4923,
                        4923,
                        7180,
                        7510,
                        7615,
                        4513,
                        4928,
                        4800,
                        5311,
                        6127,
                        6095


    
                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsColegialesTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        3920,
                        4102,
                        4348,
                        4594,
                        4594,
                        4702,
                        4801,
                        3593,
                        5028,
                        4628,
                        5406,
                        5950,
                        5719,
                        5603



    
                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsFonaviTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        4258,
                        4357,
                        4562,
                        5048,
                        5048,
                        3764,
                        4787,
                        4961,
                        5866,
                        3994,
                        4612,
                        5121,
                        5049,
                        5178

                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsRanchosTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        6325,
                        6559,
                        7300,
                        7914,
                        7914,
                        10828,
                        9017,
                        9299,
                        8952,
                        9103,
                        9913,
                        8673,
                        8633,
                        9736


                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsRuta3SurTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        5340,
                        5784,
                        5944,
                        6466,
                        6466,
                        5607,
                        5316,
                        5425,
                        5842,
                        6123,
                        5082,
                        4681,
                        5417,
                        5589

                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsStaTeresitaTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        3187,
                        3482,
                        3640,
                        3917,
                        3917,
                        4205,
                        4656,
                        4412,
                        6238,
                        6012,
                        6409,
                        6253,
                        6181,
                        5151


                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsMunicipalTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        2706,
                        2889,
                        2921,
                        3029,
                        3029,
                        3716,
                        4094,
                        3814,
                        4466,
                        4795,
                        3425,
                        4324,
                        3958,
                        4608



                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsVillaItaliaTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        3896,
                        4172,
                        4298,
                        4403,
                        4403,
                        6825,
                        6977,
                        6196,
                        7681,
                        7107,
                        7599,
                        6836,
                        6758,
                        6857
                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "cicOlimpoTotalData" : {
            labels: [
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        4623,
                        5910,
                        6211,
                        4978,
                        9044,
                        9340,
                        6158,
                        5700,
                        6436


                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },

        "capsTotalData" : {
            labels: [
                2009,
                2010,
                2011,
                2012,
                2013,
                2014,
                2015,
                2016,
                2017,
                2018,
                2019,
                2020,
                2021,
                2022
            ],
            datasets: [
                {   label: "Consultas totales",
                    data: [
                        39910,
                        42216,
                        44730,
                        48158,
                        48158,
                        60625,
                        63136,
                        63219,
                        64883,
                        67964,
                        69919,
                        66073,
                        66235,
                        68848


                        
                    ],
                    backgroundColor:'#85bbe2'
                }
            ]
        },
    }


    const [actualData, setData] = useState(datosSalas.capsTotalData);

    const options = {
        scales: {
             xAxes: [{
                 stacked: props.stacked,
                 ticks: {
                    beginAtZero:true,
                    fontSize:14
                }
             }],
             yAxes: [{
                 stacked: props.stacked,
                 ticks: {
                    beginAtZero:true,
                    fontSize:14
                 },
             }]
        },
        title: {
            display:true,
            text: props.title,
            fontSize:16
        }
     }

    function handleChange(e) {
        e.preventDefault();
        setData(datosSalas[e.target.value]);
    }


    return (
        <div>    
            {props.dataSelect &&    

            <>
                <Form.Control as="select" onChange={handleChange}>
                    <option value="capsTotalData" >Todas las salas</option>
                    <option value="capsBMachadoTotalData" >CAPS Benito Machado</option>
                    <option value="caps25deMayoTotalData">CAPS 25 de Mayo</option>
                    <option value="cicBocaTotalData">CIC Boca</option>
                    <option value="capsColegialesTotalData">CAPS Colegiales</option>
                    <option value="capsFonaviTotalData">CAPS Fonavi</option>
                    <option value="capsRanchosTotalData">CAPS Ranchos</option>
                    <option value="capsRuta3SurTotalData">CAPS Ruta 3 Sur</option>
                    <option value="capsStaTeresitaTotalData">CAPS Santa Teresita</option>
                    <option value="capsMunicipalTotalData">CAPS Municipal</option>
                    <option value="capsVillaItaliaTotalData">CAPS Villa Italia</option>
                    <option value="cicOlimpoTotalData">CIC Olimpo</option>
                </Form.Control>                
            </>

            }
        
            < Bar data={actualData} options={options} height={props.height} />
        
        </div>
    )
}

export default BarChartSelectSalas



 //TODO: porque como componente no funciona? tuve que agregar el condicional de JSX para renderizar el fragmento. Investigar
    /*
    function DataSelect(dataSelect){
        if (dataSelect) {
            return (
                      
                <>
                <Form.Control as="select" onChange={handleChange}>
                    <option value="capsBMachadoTotalData" >Caps Benito Machado</option>
                    <option value="caps25deMayoTotalData">Caps 25 de Mayo</option>
                </Form.Control>
                </>
                
            )
        }else{
            return null;
        } 
    }
    */