import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
//import BarChart from './BarChart.jsx';
import {
    datosPresupuestarios
} from '../data/data.js';

//import BarChartSelectSalas from './BarChartSelectSalas.jsx';
import DoughnutChart from './DoughnutChart';
import ChartDataLabels from 'chartjs-plugin-datalabels';

function PresupuestoGastosOrigen() {

  return (
    <div>
      <Container id="contain">
      <br/>
      <h3>¿Quién gasta?</h3>
      <hr/>
        <Row>
          <Col>
            <br />
            <p>Aquí se puede ver cuáles son las aperturas programáticas encargadas de ejecutar el presupuesto.  Comprende todos los niveles de la programación y su formulación depende directamente de la definición de la estrategia sanitaria.</p>
            <p>Es la expresión de los diferentes niveles de actividad, que se estructura en función de los objetivos y metas institucionales. Es por eso que posibilita visualizar la expresión financiera de las políticas de desarrollo.</p>
            <p>Las categorías programáticas permiten establecer la responsabilidad de cada funcionario por la administración de los recursos reales y financieros asignados a cada nivel.</p>
            <p>De esta manera, se puede apreciar los recursos financieros que se requieren para adquirir los recursos reales.</p>
          </Col>
          <Col>
            <DoughnutChart plugins={[ChartDataLabels]} data={datosPresupuestarios().origenes_gastos} height={300} stacked={false} title="Distribución de gastos de presupuesto 2022"/>             
          </Col>
        </Row>
        <hr />
      </Container>
    </div>
  );
}

export default PresupuestoGastosOrigen;