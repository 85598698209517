import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import Carousel from 'react-bootstrap/Carousel';
import { useState } from 'react';


function Futuro() {


    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    return (
        <Container>
          <Row>
            <Col>
                <br /> 
                <h5>Futuro</h5>
                

                <div class="embed-responsive embed-responsive-16by9">
                  <iframe class="embed-responsive-item" src="https://www.youtube.com/embed/ahqwiSzRsbM?rel=0&modestbranding=1" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
                </div>             

                <hr/>
                <h2>Sala de conferencias y comité de docencia e investigación</h2>

                <p>Unidos a Rotary Club Tres Arroyos construiremos la sala de conferencias y comité de docencia e investigación de nuestro Centro Municipal de Salud del Pdo de Tres Arroyos </p>

                <p> Queremos seguir avanzando y hoy como nueva meta nos proponemos jerarquizar la formación de nuestro personal. </p>

                <p>Construir el Auditorio, la Sala de Conferencias y el comité de docencia e investigación del Distrito de Tres Arroyos que tendrá un aula integrada a biblioteca y sala de reuniones – aula independiente y sala de conferencias que comprenderá una superficie total de 184 m<sup>2</sup>. </p>

                <p> ¡Sabemos que contamos con vos! </p>
                
                <p> Podes colaborar al ALIAS: <b>OBRA.AULA.HOSPITAL</b> </p>

                <p> Si queres saber más:  <a href="/evolucion" > toda nuestra evolución, año a año</a>
                <br />
                #unidosconstruimossalud

                </p>


                <Carousel activeIndex={index} onSelect={handleSelect} fade="true" interval="1000000">

                <Carousel.Item>
                        <img 
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_futuro/auditorio.jpg"
                        alt="First slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_futuro/auditorio2.jpg"
                        alt="Second slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_futuro/auditorio3.jpg"
                        alt="Third slide"
                        />
                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_futuro/biblioteca.jpg"
                        alt="Fourth slide"
                        />

                    </Carousel.Item>

                    <Carousel.Item>
                        <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/carrousel_futuro/aula.jpg"
                        alt="Fifth slide"
                        />

                    </Carousel.Item>
                
                </Carousel>

                <br />
                <br />

                <p> <b>Planos (clic para descargar en PDF) </b> </p>
                <br />

                <Row>
                <Col> 
                <a href="http://gestionabierta.centrodesalud.com.ar/images/futuro/plano1.pdf" download>
                    <img
                        className="d-block w-75"
                        src="http://gestionabierta.centrodesalud.com.ar/images/futuro/plano1.jpg"
                        alt="First slide"
                    />
                    </a>
                </Col>
                <Col> 
                <a href="http://gestionabierta.centrodesalud.com.ar/images/futuro/plano2.pdf" download>
                    <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/futuro/plano2.jpg"
                        alt="First slide"
                    />
                    </a>
                    <br/>
                <a href="http://gestionabierta.centrodesalud.com.ar/images/futuro/plano3.pdf" download>
                    <img
                        className="d-block w-100"
                        src="http://gestionabierta.centrodesalud.com.ar/images/futuro/plano3.jpeg"
                        alt="First slide"
                    />
                    </a>
                </Col>
                </Row>


                <br />

                


                 

                
            
                <br></br>

            </Col>
          </Row>
        </Container>
    )
}

export default Futuro