import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BarChart from './BarChart.jsx';
import {
    consultasHospital
} from '../data/data.js';

function AsistenciaHospital() {

  return (
    <div>
      <Container id="contain">
        <br/>
        <h5>Estadísticas del hospital</h5>
        <hr/>
        <h6>Atenciones en consultorios externos</h6>
        <Row>              
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().consultoriosExternosData} height={250} stacked={true} title="Evolución de consultas en consultorios externos por año"/>
          </Col>
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().consultoriosExternosTotalData} height={250} stacked={true} title="Evolución de consultas en consultorios externos por año"/>
          </Col>
        </Row>
        <hr/>
        

        <h6>Atenciones en guardia</h6>
        <Row>              
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().atencionesGuardiaData} height={250} stacked={true} title="Evolución de atenciones en guardia por año"/>
          </Col>
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().atencionesGuardiaTotalData} height={250} stacked={true} title="Evolución de atenciones en guardia por año"/>
          </Col>
        </Row>

        {/*
        <Row>
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().tasaDeCrecimiento} height={250} stacked={false} title="Consultas Hospital Pirovano"/>
          </Col>
          <Col>
          </Col>
        </Row>
        */}

        <hr/>
        <h6>Internaciones</h6>
        <Row>
          <Col>
          <BarChart data={consultasHospital()} defaultData={consultasHospital().internacionesAnualesHospital} height={250} stacked={false} title="Evolución de internaciones por año"/>

          </Col>
          <Col>
          </Col>
        </Row>
        <hr/>
        <h6>Cirugías</h6>
      
        <Row>
          <Col>
          <BarChart data={consultasHospital()} defaultData={consultasHospital().cirugiasAnualesHospital} height={250} stacked={false} title="Evolución de cirugías por año"/>

          </Col>
          <Col>

          </Col>
        </Row>
        <hr/>
        <h6>Nacimientos</h6>
        <Row>
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().nacimientosAnualesHospital} height={250} stacked={false} title="Evolución de nacimientos por año"/>
          </Col>
          <Col>
          </Col>
        </Row>

        <hr/>
        <h6>Diagnóstico por imágenes</h6>
        <Row>
          <Col>
            <BarChart data={consultasHospital()} defaultData={consultasHospital().diagnosticoPorImagenesAnuales} height={250} stacked={false} title="Evolución de estudios por año"/>
          </Col>
          <Col>
          </Col>
        </Row>


        <hr/>


        <p>Fuente de los datos: Área de Estadísticas del Centro Municipal de Salud</p>

      </Container>
    </div>
  );
}

export default AsistenciaHospital;