import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { Chrono } from "react-chrono";

function Inversiones() {

    
  const items = [
    {
      title: "Octubre 2023",
      cardTitle: "Finalizando obra de Oncologia",
      url: "https://centrodesalud.com.ar/noticia/oncologia-a-pocos-dias",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/gestor/fotos/destacadas/cds_1696331618.jpg"
        }
      }
      },
    {
      title: "Agosto 2023",
      cardTitle: "Avances importantes en el centro de salud de Copetonas",
      url: "https://centrodesalud.com.ar/noticia/ya-tenemos-un-avance-del-70-de-la-obra",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/gestor/fotos/destacadas/cds_1693092730.jpg"
        }
      }
      },
    {
      title: "Agosto 2023",
      cardTitle: "Equipamiento y nuevos médicos para diagnóstico por imágenes",
      url: "https://centrodesalud.com.ar/noticia/diagnostico-por-imagenes-incorporamos-profesionales-y-equipamiento",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/gestor/fotos/destacadas/cds_1693091803.jpg"
        }
      }
      },
    {
      title: "Julio 2023",
      cardTitle: "Obra y equipamiento para Oncología",
      url: "https://centrodesaludtsas.com.ar/noticia/oncologia-obra-y-equipamiento",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1689852051.jpg"
        }
      }
      },
    {
      title: "Julio 2023",
      cardTitle: "Remodelación de Salud Mental",
      url: "https://centrodesaludtsas.com.ar/noticia/salud-mental-tambien-remodelado",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1689079371.jpg"
        }
      }
      },
    {
      title: "Junio 2023",
      cardTitle: "Remodelación de clínica quirúrgica",
      url: "https://centrodesaludtsas.com.ar/noticia/clinica-quirurgica-tambien-remodelado-a-nuevo",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1687796333.jpg"
        }
      }
      },
    {
      title: "Junio 2023",
      cardTitle: "Gran inversión en equipamiento para quirófano",
      url: "https://centrodesaludtsas.com.ar/noticia/quirofano-invertimos-mas-de-10-millones-en-equipamiento",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1686928184.jpg"
        }
      }
      },
    {
      title: "Mayo 2023",
      cardTitle: "Adquisición de ecógrafo de última generación",
      url: "https://centrodesaludtsas.com.ar/noticia/tenemos-nuevo-ecografo-de-ultima-generacion",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1684015627.jpg"
        }
      }
      },
    {
      title: "Abril 2023",
      cardTitle: "Finalización de remodelización de CAPS Villa Italia",
      url: "https://centrodesaludtsas.com.ar/noticia/villa-italia-finalizamos-la-remodelacion-de-su-caps",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1681495373.jpg"
        }
      }
      },
    {
      title: "Marzo 2023",
      cardTitle: "Finalización de la obra de gas en Orense",
      url: "https://centrodesalud.com.ar/noticia/orense-finalizamos-la-obra-de-gas-tenemos-gas-de-red",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/gestor/fotos/destacadas/cds_1679508170.jpg"
        }
      }
      },
    {
      title: "Diciembre 2022",
      cardTitle: "Fin de remodelación de sala de 25 de mayo",
      url: "https://centrodesaludtsas.com.ar/noticia/caps-25-de-mayo-finalizamos-su-remodelacion",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/gestor/fotos/destacadas/cds_1672143917.jpg"
        }
      }
      },
    {
      title: "Noviembre 2022",
      cardTitle: "Nuevo servicio de Patología",
      url: "https://centrodesalud.com.ar/noticia/nuevo-servicio-de-Patologia-y-homenajes",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1669836395.jpeg"
        }
      }
      },
    {
      title: "Noviembre 2022",
      cardTitle: "Refacción de CAPS Benito Machado",
      url: "https://centrodesaludtsas.com.ar/noticia/benito-machado-refaccionamos-el-caps-junto-con-los-vecinos",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1668274373.jpg"
        }
      }
      },
    {
      title: "Septiembre 2022",
      cardTitle: "Inicio de remodelación de pasillos y áreas comunes",
      url: "https://centrodesaludtsas.com.ar/noticia/nuestros-pasillos-y-areas-comunes-iniciamos-remodelacion",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesaludtsas.com.ar/storage/fotos/destacadas/cds_1664211980.jpg"
        }
      }
      },
    {
      title: "Julio 2022",
      cardTitle: "Fin de remodelación de CIC Olimpo",
      url: "https://centrodesalud.com.ar/noticia/cic-olimpo-finalizamos-su-remodelacion",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1662128648.jpg"
        }
      }
      },
    {
      title: "Julio 2022",
      cardTitle: "Remodelación del Centro de Salud de Barrio Fonavi",
      url: "https://centrodesalud.com.ar/noticia/fonavi-finalizamos-la-obra",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658834151.jpg"
        }
      }
      },
    {
      title: "Julio 2022",
      cardTitle: "Remodelación del Centro de Salud de Barrio Ranchos",
      url: "https://centrodesalud.com.ar/noticia/sanchez-visito-la-remodelacion-del-caps-de-ranchos",
      //cardSubtitle:"Superficie afectada: 100km2",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658323112.jpg"
          
        }
      }
      },
    {
    title: "Enero 2022",
    cardTitle: "Remodelación y techado del geriátrico",
    url: "https://centrodesalud.com.ar/noticia/nuevo-techo-para-el-geriatrico",
    //cardSubtitle:"Superficie afectada: 100km2",
    //cardDetailedText: "",
    media: {
      type: "IMAGE",
      source: {
        url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658337317.jpg"
      }
    }
    },
    {
      title: "Noviembre 2021",
      cardTitle: "Instalación de planta productora de oxígeno",
      url: "https://centrodesalud.com.ar/noticia/Tres-Arroyos-ya-genera-oxigeno-medicinal",
      //cardSubtitle:"Su",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658323389.jpg"
        }
      }
    },
    {
      title: "Septiembre 2021",
      cardTitle: "Remodelación de Ginecología y Maternidad",
      url: "https://centrodesalud.com.ar/noticia/maternidad-otro-servicio-que-remodelamos-a-nuevo",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1647452702.jpg"
        }
      }
    },
    {
      title: "Agosto 2021",
      cardTitle: "Remodelación del Centro de Salud de Colegiales",
      url: "https://centrodesalud.com.ar/noticia/Remodelamos-el-centro-de-Salud-de-Colegiales",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658323522.jpeg"
        }
      }
    }
    ,
    {
      title: "Marzo 2021",
      cardTitle: "Remodelaciones en Clínica Médica",
      url: "https://centrodesalud.com.ar/noticia/clinica-medica-remodelada-a-nuevo",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "Con 150 metros cuadrados de cómodas instalaciones compuestas por hall de espera, espacios para la atención de pacientes ambulatorios y la recepción específica para la provisión de insumos y medicamentos. ",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658323797.jpg"
        }
      }
    }
    ,
    {
      title: "Enero 2021",
      cardTitle: "Fin de remodelaciones en CIC Boca",
      url: "https://centrodesalud.com.ar/noticia/Cic-de-Boca-Finalizamos-la-remodelaci%C3%B3n",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658324039.jpg"
        }
      }
    }
    ,
    {
      title: "Enero 2021",
      cardTitle: "Remodelación de Laboratorio",
      url: "https://centrodesalud.com.ar/noticia/laboratorio-de-PCR-propio",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658325986.jpg"
        }
      }
    }
    ,
    {
      title: "Noviembre 2020",
      cardTitle: "Inauguración de Farmacia",
      url: "https://centrodesalud.com.ar/noticia/tenemos-nueva-farmacia",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658326820.jpg"
        }
      }
    }
    ,
    {
      title: "Agosto 2020",
      cardTitle: "Oxígeno central en Pediatría",
      url: "https://centrodesalud.com.ar/noticia/ahora-pediatria-tiene-oxigeno-central",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658330744.jpg"
        }
      }
    }
    ,
    {
      title: "Julio 2020",
      cardTitle: "Remodelación de quirófanos",
      url: "https://centrodesalud.com.ar/noticia/nuestros-nuevos-quirofanos-1",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658327600.jpg"
        }
      }
    }
    ,
    {
      title: "Octubre 2019",
      cardTitle: "Ampliación de sector de Vacunación",
      url: "https://centrodesalud.com.ar/noticia/nueco-espacio-para-vacunacion",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658338359.jpg"
        }
      }
      
    }
    ,
    {
      title: "Agosto 2019",
      cardTitle: "Ampliación de sector de Esterilización",
      url: "https://centrodesalud.com.ar/noticia/el-intendente-inauguro-el-nuevo-servicio-de-esterilizacion",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658338814.jpg"
        }
      }
      
    }
    ,
    {
      title: "Noviembre 2018",
      cardTitle: "Taller de ambulancias propio",
      url: "https://centrodesalud.com.ar/noticia/inauguracion-del-taller-de-ambulancias",
      //cardSubtitle:"Subtitulo de la noticia",
      //cardDetailedText: "",
      media: {
        type: "IMAGE",
        source: {
          url: "https://centrodesalud.com.ar/storage/fotos/destacadas/cds_1658339524.jpg"
        }
      }
      
    }
  
  
  ];

    return (
        <Container>
          <Row>
            <Col>
                <br /> 
                <h5>Inversiones</h5>
                <hr/>

                <p>En esta sección podrá visualizar la evolución de las inversiones en infraestructura.</p>

                <b>Inversiones por año</b>
               

                <div style={{ width: "1000px", height: "100%" }}>
                  <Chrono 
                  
                  items={items}

                  theme={{
                    primary: '#85bbe2',
                    secondary: '#a4d280',
                    cardBgColor: 'white',
                    //cardForeColor: 'violet',
                    titleColor: '#a4d280',
                    titleColorActive: 'white',
                    cardTitleColor: 'white',
                  }}

                  fontSizes={{
                    cardSubtitle: '0.75rem',
                    cardText: '0.7rem',
                    cardTitle: '0.9rem',
                    title: '0.9rem',
                  }}
                
                  hideControls="true"

                  mode="VERTICAL_ALTERNATING"/>
                </div>


            </Col>
          </Row>
        </Container>
    )
}

export default Inversiones