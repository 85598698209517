import React from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import BarChart from './BarChart.jsx';
import {
    consultasTotalesCaps
} from '../data/data.js';

//import BarChartSelectSalas from './BarChartSelectSalas.jsx';
//import DoughnutChart from './DoughnutChart';
//import ChartDataLabels from 'chartjs-plugin-datalabels';

function Asistencia_Unidades_Localidades() {

  return (
    <div>
      <Container id="contain">
      <br/>
      <h5>Estadísticas de los Centros de Atención Primaria de las localidades</h5>
      <hr/>
        <Row>
          <Col>
            <BarChart data={consultasTotalesCaps()} defaultData={consultasTotalesCaps().todos_localidades} height={250} stacked={false} title="Consultas Centros de Atención Primaria de las localidades"/>             
          </Col>
          <Col></Col>

          {/*
          <Col>
            <BarChart data={consultasTotalesCaps()} defaultData={consultasTotalesCaps().tasa_crecimiento_localidades} height={250} stacked={false} title="Consultas Centros de Atención Primaria de las localidades"/>            
          </Col>

          */}
        </Row>

        <hr/>


        <p>Fuente de los datos: Área de Estadísticas del Centro Municipal de Salud</p>
      </Container>
    </div>
  );
}

export default Asistencia_Unidades_Localidades;